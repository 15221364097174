$primary-c: #303f9f;
$danger-c: #f44336;

:root{
  --secondary-c: #2C3539;
  --default-c: white; 
  --primary-c: $primary-c;
  --danger-c: $danger-c;
}

body {
  background-color: var(--secondary-c);
}

.Mui-error {
  .MuiInputBase-input{
    &:focus{
      background: lighten($danger-c, 40%);
      border-radius: 5px;
    }
  }
}

.Mui-focused{
  .MuiInputBase-input{
    background: lighten($primary-c, 40%);
    border-radius: 5px;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
