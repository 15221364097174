.payment{
  margin: 20px 0;
  position: relative;
  min-height: 700px;
  &-group{
    display: flex;
  }
  &-footer{
    padding: 0px 10px 10px 10px;
    width: 100%;
  }
  &-content{
    // position: relative;
  }
  &-submit{
    background-color: var(--primary-c);
    background: var(--default-c);
    padding:35px;
  }

  .cancel-iframe-btn {
    margin-bottom: 10px;
  }
}

.payment_message {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--default-c);
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  &-toolbar{
    position: absolute;
    top: 0;
    text-align: right;
    width: 100%;
  }
  &-icon{
    font-size: 120px;
  }
  &-amount{
    font-size: 60px;
  }
  &-code{
    padding: 50px 0 0;
  }
}