.edit_field{
  display: flex;
  &-button{
    position: absolute;
  }
  &-display_content{
    display: flex;
    align-items: center;
    flex: 1 1 100%;

    p:first-of-type{
      width: 100%;
      font-weight: bold;
    }
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0; 
  }
}